<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important

.toc
  position: fixed
  top: 0px
  right: 0px
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-center(style='min-height: 300px')
    .col-10(style='min-width: 600px')
      .mb-4
      h3.title.py-3 이메일 발신자 설정

      .toc.p-2
      div.async(:class='{done: done}')

        form.form(@submit.prevent='submit()' v-if='config')
          .form-group
            label.d-block 등록된 발신자
            .bg-light.rounded.p-4
              .bg-white.border.p-4.rounded.shadow-sm(v-for='domain in config.domains')
                strong {{domain.subdomain && domain.subdomain+'.'}}{{domain.domain}}
                strong.float-right
                  span.text-success(v-if='domain.response_json.valid') 인증완료
                  span.text-muted(v-else)
                    b-icon-exclamation-circle.mr-2
                    | 인증대기중
                //- pre {{domain.response_json}}
                div(v-if='!domain.response_json.valid')
                  div(style='font-size: 12px; overflow: scroll')
                    p.alert.alert-warning.mt-2(v-show='domain.response_json.dns.mail_cname.reason')
                      b-icon-exclamation-triangle.mr-2
                      strong.mr-4 오류
                      strong {{domain.response_json.dns.mail_cname.reason}}
                    p.alert.alert-warning.mt-2(v-show='domain.response_json.dns.dkim1.reason')
                      b-icon-exclamation-triangle.mr-2
                      strong.mr-4 오류
                      strong {{domain.response_json.dns.dkim1.reason}}
                    p.alert.alert-warning.mt-2(v-show='domain.response_json.dns.dkim2.reason')
                      b-icon-exclamation-triangle.mr-2
                      strong.mr-4 오류
                      strong {{domain.response_json.dns.dkim2.reason}}
                    table.table.table-bordered.border.mt-4
                      thead
                        tr
                          th TYPE
                          th HOST
                          th VALUE
                      tbody
                        tr(:class='{"text-danger bg-light":(domain.response_json.dns.mail_cname.reason)}')
                          td {{domain.response_json.dns.mail_cname.type}}
                          td {{domain.response_json.dns.mail_cname.host}}
                          td {{domain.response_json.dns.mail_cname.data}}
                        tr(:class='{"text-danger bg-light":(domain.response_json.dns.dkim1.reason)}')
                          td {{domain.response_json.dns.dkim1.type}}
                          td {{domain.response_json.dns.dkim1.host}}
                          td {{domain.response_json.dns.dkim1.data}}
                        tr(:class='{"text-danger bg-light":(domain.response_json.dns.dkim2.reason)}')
                          td {{domain.response_json.dns.dkim2.type}}
                          td {{domain.response_json.dns.dkim2.host}}
                          td {{domain.response_json.dns.dkim2.data}}
                  button.btn.btn-light.text-primary(type='button' @click='verify_sender_domain_id(domain.id)') DNS 설정을 완료했습니다.
                div(v-else)
                  table.table.table-bordered.border.mt-4(style='font-size: 12px')
                    thead
                      tr
                        th ADDRESS
                        th NAME
                        th REPLY TO
                    tbody
                      tr
                        td {{domain.username + '@' + (domain.subdomain+'.' || '') + domain.domain}}
                        td {{domain.from_name}}
                        td {{domain.reply_to}}
              .bg-white.border.p-4.rounded.shadow-sm.mt-2(v-for='shared in config.shared')
                strong 발급받은 공용 이메일 주소
                small.text-muted.float-right {{shared.created_at}}
                //- pre {{domain.response_json}}
                div(style='font-size: 12px; overflow: scroll')
                  table.table.table-bordered.border.mt-4(style='font-size: 12px')
                    thead
                      tr
                        th ADDRESS
                        th NAME
                        th REPLY TO
                    tbody
                      tr
                        td {{shared.username + shared.address}}
                        td {{shared.from_name}}
                        td {{shared.reply_to}}
              .pb-4
              small.ml-2.text-muted {{config.domains.length + config.shared.length}}건

          .mb-4

        .clearfix
        .bg-white.border.p-4.rounded.shadow-sm(v-if='ENV!=`production`')
          h5.title 본인 도메인 주소 등록
            strong.badge.badge-warning.float-right.p-2 QA
          .pb-4
          form.form(@submit.prevent='submit()')
            .form-group
              label.d-block Domain
                span.required *
              input.form-control(type='text' v-model='form.domain' required)
              p.text-muted.pt-2 등록 희망하는 발신자 메일주소에서 support@
                span.text-dark.bg-light(style='border-bottom: solid 1px #555') {{form.domain || 'noitaler.com'}}
                span.ml-1 밑줄친 부분을 입력해주세요.
            .form-group
              label.d-block subdomain
              input.form-control(type='text' v-model='form.subdomain')
              p.text-muted.pt-2(v-show='form.subdomain') support@{{form.subdomain}}.{{form.domain}}
            .form-group
              label.d-block username
                span.required *
              input.form-control(type='text' v-model='form.username' required)
              p.text-muted.pt-2(v-show='form.username') {{form.username}}@{{form.subdomain && form.subdomain+'.'}}{{form.domain}}
            .form-group
              label.d-block from name
                span.required *
              input.form-control(type='text' v-model='form.from_name' required)
              p.text-muted.pt-2(v-show='form.from_name') {{form.from_name}} <{{form.username}}@{{form.subdomain && form.subdomain+'.'}}{{form.domain}}>
            .form-group
              label.d-block reply to
              input.form-control(type='text' v-model='form.reply_to')
            .form-group
              button.btn.btn-primary(type='submit' :disabled='saving') {{saved_text}}
          .clearfix

        .clearfix
        .bg-white.border.p-4.rounded.shadow-sm.mt-4
          h5.title 공용 이메일 주소 발급
          .pb-4
          form.form(@submit.prevent='submit_shared()')
            .form-group
              label.d-block Username
                span.required *
              input.form-control(type='text' v-model='form_shared.username' required)
              p.text-muted {{form_shared.username}}@em8164.noitaler.com
            .form-group
              label.d-block From Name
                span.required *
              input.form-control(type='text' v-model='form_shared.from_name' required)
              p.text-muted 보내는 사람 표시
            .form-group
              label.d-block Reply to
              input.form-control(type='text' v-model='form_shared.reply_to')
              p.text-muted 답변 받을 메일주소를 적어주세요.
            .form-group
              button.btn.btn-primary(type='submit' :disabled='saving') 저장하기
          .clearfix

        .clearfix
        .bg-white.border.p-4.rounded.shadow-sm.mt-4
          h5.title 하단 푸터
          .pb-4
          form.form(@submit.prevent='submit_footer()')
            .form-group
              label.d-block 문구
                span.required *
              input.form-control(type='text' v-model='form_footer.text' required)
              p.text-muted 메일 하단에 들어가는 문구입니다. 메일 보내는 회사명 주소를 넣어주세요.
            .form-group
              label.d-block 수신거부 문구
                span.required *
              input.form-control(type='text' v-model='form_footer.unsubscribe' required)
            .form-group
              button.btn.btn-primary(type='submit' :disabled='saving') 저장하기
          .clearfix

</template>

<script>

export default {
  name: 'manage-email',
  props: ['property_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  watch: {
    '$store.state.email_config'() {
      this.load()
    },
  },
  mounted() {
    // this.load()
    this.$store.dispatch('email config', this.property_id)
  },
  data() {
    return {
      ENV: process.env.NODE_ENV,
      done: false,
      saving: false,
      saved_text: '저장하기',
      form_shared: {
        username: '',
        reply_to: '',
      },
      form: {
        domain: '',
        subdomain: '',

        // from_email: '',
        // from_name: '',
        // reply_to: '',
        // reply_to_name: '',
        // address: '',
        // address2: '',
        // // state: '',
        // city: '',
        // zip: '',
        // country: '',
      },
      form_footer: {
        text: '',
        unsubscribe: '',
      },
      config: {
        domains: [],
        shared: [],
      },
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get(`/v1/property/${this.property_id}/email_config`)
        // if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '가져오기 실패')

        // let config = r.data
        // if (!config.emails || config.emails.length === 0) {
        //   config = {
        //     emails: [
        //       {
        //         address: '',
        //         name: '',
        //       },
        //     ]
        //   }
        // }
        // this.config = config
        this.config = this.$store.state.email_config

        if (this.config.footer) {
          this.form_footer = this.config.footer
        }
        this.done = true
      } catch (error) {
        console.log({error})
      }
    },
    async submit() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/email/verify_sender_domain`, this.form)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '인증 요청 실패')

        this.saved_text = '요청됨'
        this.$store.dispatch('email config', this.property_id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '인증 요청했습니다. 페이지 위에 [인증대기중] 상태인 도메인 정보로 DNS 설정을 완료해주세요. 그리고 [DNS 설정을 완료했습니다.] 버튼을 누르면 인증이 끝납니다.  '
        })

        this.$store.dispatch('property', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit_shared() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/email/assign_shared_domain`, this.form_shared)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '인증 요청 실패')

        this.$store.dispatch('email config', this.property_id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '발급했습니다.'
        })

        this.form_shared.username = ''
        this.form_shared.reply_to = ''

        this.$store.dispatch('property', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit_footer() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/email_config/footer`, this.form_footer)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '푸터 저장 실패')

        this.$store.dispatch('email config', this.property_id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.'
        })

        this.load()

        this.$store.dispatch('property', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async verify_sender_domain_id(id) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/email/verify_sender_domain/${id}`)
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '인증 DNS 확인요청 실패')

        this.$store.dispatch('email config', this.property_id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '인증되었습니다.'
        })

        this.$store.dispatch('property', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

  },
}
</script>
